import {  SensorType } from "../dataTypes/myDataTypes";
import { MyGaugeComp } from "./myGaugeComp";


//=================================================================================================================
interface SensorDivProps {
  sensor: SensorType;
  displayZoneName:boolean;
  displaySensorName:boolean;
  dispGraphics:boolean;
}
//-----------------------------------------------------------------------------------------------------------------
export function SensorDiv({ sensor,displayZoneName,displaySensorName,dispGraphics}: SensorDivProps) {
  let title = '';
  if(displayZoneName) title += sensor.zone;
  if(displaySensorName) title += sensor.name;
    return (
      dispGraphics?
      <MyGaugeComp
        title={title }
        unit={sensor.unit}
        val={sensor.value}
        minVal={sensor.minVal}
        maxVal={sensor.maxVal}
        colors={sensor.colors}
        segments={sensor.segments}
      />
      :
      <div
        style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          flexDirection:'column',
          color:'white  ',
          backgroundColor:'rgb(65, 169, 255)',
          padding:'7px',
          borderRadius:'10px',
          width:'135px'

        }}
        title={sensor.unit}
      >
        <b
          style={{
            fontFamily:'MYLCD',
            // color:'rgb(65, 169, 255)',


          }}
        >
          {title}
        </b>
        <span
          style={{
            fontFamily:'MYLCD',
            fontSize:'54px',
            // color:'rgb(65, 169, 255)'

            
          }}
        >
          {sensor.value.toFixed(sensor.decimal)}
        </span>
      </div>
      
    );
  }
//=================================================================================================================
interface SensorListDivProps {
  sensors: SensorType[];
  displayZoneName:boolean;
  displaySensorName:boolean;
  dispGraphics:boolean;
}
//-----------------------------------------------------------------------------------------------------------------

export function SensorListDiv({sensors,displayZoneName,displaySensorName,dispGraphics}: SensorListDivProps) {
  
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        gap: 20,
        padding: 10,
      }}
    >
      {sensors.map((s) => (
        <SensorDiv
          key={`${s.zone}-${s.name} `}
          sensor={s}
          dispGraphics={dispGraphics}
          displayZoneName = {displayZoneName}
          displaySensorName = {displaySensorName}
        />
      ))}
    </div>
  );
}
//=================================================================================================================
