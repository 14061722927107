import { Alert, colors, TextField } from "@mui/material";
import { useState } from "react";
import auth from "../services/auth";
import "../../src/index.css";
import logo_dark from "../assets/WhatsApp Image 2024-10-07 at 19.41.32_54c75195.png";
import "./navbar/navbar.css";
import { ColorLens } from "@mui/icons-material";

interface MyLoginFormProps {
  onLog: () => void;
}
//==================================================
export function MyLoginForm({ onLog }: MyLoginFormProps) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [invalidUser, setInvalidUser] = useState(false);

  async function validateUser() {
    let validUser = await auth.loginUser(username, password);
    console.log('validUser',validUser);
    
    if (validUser) onLog();
    else setInvalidUser(true);
  }

  return (
    <>
      <div className="containerLog">
        <div className="logIn">
          
          <div
          style={{
            display:'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor:'rgb(65, 169, 255)',
            padding:'10px',
            borderRadius:'10px',
            color:'white'
          }}>
            <div
            style={{
              backgroundColor:'white',
              borderRadius:'12px',
              display:'flex'
            }}>
            <img src={logo_dark} alt="" className="logo" />
            </div>
          <TextField
            sx={{
              outline: {},
              input: { color: "white", borderColor: "white" },
              margin: "20px",
            }}
            label="User Name"
            
            variant="outlined"
            value={username}
            onChange={(e) => {
              setUserName(e.target.value);
              // setInvalidUser(false);
            }}
          />
          <TextField
            sx={{ input: { color: "white" } }}
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              // setInvalidUser(false);
            }}
            onKeyDown={(e)=>{
              if(e.key == 'Enter'){
                validateUser()
              }
                
            }}
          />
          </div>
          <button
            onClick={()=>{validateUser()}}
          >
            Login
          </button>
        </div>
      </div>
      {invalidUser && (
        <Alert variant="filled" severity="error">
          Invalid user, try again
        </Alert>
      )}
    </>
  );
}
