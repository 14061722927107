// import { DMemoryType } from "../dataTypes/myDataTypes";

interface PClockprops {
  devDataArr: string[];
}

//==================================================
export function PClock({ devDataArr }: PClockprops) {
  if (devDataArr.length < 100) return null;

  // if (!DMemory[0]) return null;
  // let dArr = dataStr.substring(1, dataStr.length - 1).split(",");

  let s: number = parseInt(devDataArr[0]);
  let m: number = parseInt(devDataArr[1]);
  let h: number = parseInt(devDataArr[2]);
  
  return (
    <div
      style={{
        // padding:'5px',
      }}
    ><span
    style={{
      fontFamily:'MYLCD',

     
    }}>
       {(h<10)?`0${h}`:h}:{(m<10)?`0${m}`:m}:{(s<10)?`0${s}`:s}
       </span>
    </div>
  );
}
//==================================================
