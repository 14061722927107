import { SensorType, ZoneType } from "../dataTypes/myDataTypes";
let pHColors:string[] = ['#ff0000','#fe6b3a','#ea9949','#ffd556','#c0e271','#d6f78a','#05a312','#67b992','#83e6e7','#44abff','#136cff','#5f44f3','#8f44f3','#3f038f'];
let tempColors:string[] = ['#0000FF','#FF0000'];
let ecColors:string[] = ['#038f6f','#FFA500'];
let rhColors:string[] = ['#FF0000','#FFA500'];
let pressureColors:string[] = ['#FF0000','#FFA500'];

let pHSegments = 14;
let tempSegments = 16;
let ecSegments = 10;
let rhSegments = 10;
let pressureSegments = 8;




export let zoneNames:string[] = [
    'Zone1',
    'Zone2',
    'Zone3',
    'Zone4',
    'Zone5',
    'Zone6',
]



export let sensors:SensorType[] = [
    {   
        name:'EC',
        zone:'Zone1',
        address:10,
        factorAddress:11,
        decimal:3,
        factor:0,
        minVal:0,
        maxVal:50,
        unit:' (mS/cm)',
        colors:ecColors,
        segments:ecSegments,
        value:0,
    },    
    {   
        name:'Temp.',
        zone:'Zone1',
        address:12,
        factorAddress:13,
        factor:0,
        decimal:2,
        minVal:-10,
        maxVal:60,
        unit:' ( ° C)',
        colors:tempColors,
        segments:tempSegments,
        value:0,
    },    

    {   
        name:'EC',
        zone:'Zone2',
        address:14,
        factorAddress:15,
        decimal:3,
        factor:0,
        minVal:0,
        maxVal:50,
        unit:' (mS/cm)',
        colors:ecColors,
        segments:ecSegments,
        value:0,
    },    
    {   
        name:'Temp.',
        zone:'Zone2',
        address:16,
        factorAddress:17,
        factor:0,
        decimal:2,
        minVal:-10,
        maxVal:60,
        unit:' ( ° C)',
        colors:tempColors,
        segments:tempSegments,
        value:0,
    }, 

    {   
        name:'EC',
        zone:'Zone3',
        address:18,
        factorAddress:19,
        decimal:3,
        factor:0,
        minVal:0,
        maxVal:50,
        unit:' (mS/cm)',
        colors:ecColors,
        segments:ecSegments,
        value:0,
    },    
    {   
        name:'Temp.',
        zone:'Zone3',
        address:20,
        factorAddress:21,
        factor:0,
        decimal:2,
        minVal:-10,
        maxVal:60,
        unit:' ( ° C)',
        colors:tempColors,
        segments:tempSegments,
        value:0,
    }, 

    {   
        name:'EC',
        zone:'Zone4',
        address:22,
        factorAddress:23,
        decimal:3,
        factor:0,
        minVal:0,
        maxVal:50,
        unit:' (mS/cm)',
        colors:ecColors,
        segments:ecSegments,
        value:0,
    },    
    {   
        name:'Temp.',
        zone:'Zone4',
        address:24,
        factorAddress:25,
        factor:0,
        decimal:2,
        minVal:-10,
        maxVal:60,
        unit:' ( ° C)',
        colors:tempColors,
        segments:tempSegments,
        value:0,
    }, 

    {   
        name:'EC',
        zone:'Zone5',
        address:26,
        factorAddress:27,
        decimal:3,
        factor:0,
        minVal:0,
        maxVal:50,
        unit:' (mS/cm)',
        colors:ecColors,
        segments:ecSegments,
        value:0,
    },    
    {   
        name:'Temp.',
        zone:'Zone5',
        address:28,
        factorAddress:29,
        factor:0,
        decimal:2,
        minVal:-10,
        maxVal:60,
        unit:' ( ° C)',
        colors:tempColors,
        segments:tempSegments,
        value:0,
    }, 

    {   
        name:'EC',
        zone:'Zone6',
        address:30,
        factorAddress:31,
        decimal:3,
        factor:0,
        minVal:0,
        maxVal:50,
        unit:' (mS/cm)',
        colors:ecColors,
        segments:ecSegments,
        value:0,
    },    
    {   
        name:'Temp.',
        zone:'Zone6',
        address:32,
        factorAddress:33,
        factor:0,
        decimal:2,
        minVal:-10,
        maxVal:60,
        unit:' ( ° C)',
        colors:tempColors,
        segments:tempSegments,
        value:0,
    }, 

    {   
        name:'PH',
        zone:'Zone1',
        address:34,
        factorAddress:35,
        decimal:2,
        factor:0,
        minVal:0,
        maxVal:14,
        unit:' ',
        colors:pHColors,
        segments:pHSegments,
        value:0,
    },    
    {   
        name:'PH',
        zone:'Zone2',
        address:38,
        factorAddress:39,
        decimal:2,
        factor:0,
        minVal:0,
        maxVal:14,
        unit:' ',
        colors:pHColors,
        segments:pHSegments,
        value:0,
    },    
    {   
        name:'PH',
        zone:'Zone3',
        address:42,
        factorAddress:43,
        decimal:2,
        factor:0,
        minVal:0,
        maxVal:14,
        unit:' ',
        colors:pHColors,
        segments:pHSegments,
        value:0,
    },    
    {   
        name:'PH',
        zone:'Zone4',
        address:46,
        factorAddress:47,
        decimal:2,
        factor:0,
        minVal:0,
        maxVal:14,
        unit:' ',
        colors:pHColors,
        segments:pHSegments,
        value:0,
    },    
    {   
        name:'PH',
        zone:'Zone5',
        address:50,
        factorAddress:51,
        decimal:2,
        factor:0,
        minVal:0,
        maxVal:14,
        unit:' ',
        colors:pHColors,
        segments:pHSegments,
        value:0,
    },    
    {   
        name:'PH',
        zone:'Zone6',
        address:54,
        factorAddress:55,
        decimal:2,
        factor:0,
        minVal:0,
        maxVal:14,
        unit:' ',
        colors:pHColors,
        segments:pHSegments,
        value:0,
    },  
    {   
        name:'Amb.',
        zone:'Zone1',
        address:58,
        decimal:1,
        factor:-1,
        minVal:-10,
        maxVal:60,
        unit:' ( ° C)',
        colors:tempColors,
        segments:tempSegments,
        value:0,
    },
    {   
        name:'RH',
        zone:'Zone1',
        address:59,
        decimal:1,
        factor:-1,
        minVal:0,
        maxVal:100,
        unit:' (%)',
        colors:rhColors,
        segments:rhSegments,
        value:0,
    },
    {   
        name:'Atm.',
        zone:'Zone1',
        address:60,
        decimal:2,
        factor:-2,
        minVal:90,
        maxVal:110,
        unit:' (kPa)',
        colors:pressureColors,
        segments:pressureSegments,
        value:0,
    },

    {   
        name:'Amb.',
        zone:'Zone3',
        address:61,
        decimal:1,
        factor:-1,
        minVal:-10,
        maxVal:60,
        unit:' ( ° C)',
        colors:tempColors,
        segments:tempSegments,
        value:0,
    },
    {   
        name:'RH',
        zone:'Zone3',
        address:62,
        decimal:1,
        factor:-1,
        minVal:0,
        maxVal:100,
        unit:' (%)',
        colors:rhColors,
        segments:rhSegments,
        value:0,
    },
    {   
        name:'Atm.',
        zone:'Zone3',
        address:63,
        decimal:2,
        factor:-2,
        minVal:90,
        maxVal:110,
        unit:' (kPa)',
        colors:pressureColors,
        segments:pressureSegments,
        value:0,
    },

    {   
        name:'Amb.',
        zone:'Zone5',
        address:64,
        decimal:1,
        factor:-1,
        minVal:-10,
        maxVal:60,
        unit:' ( ° C)',
        colors:tempColors,
        segments:tempSegments,
        value:0,
    },
    {   
        name:'RH',
        zone:'Zone5',
        address:65,
        decimal:1,
        factor:-1,
        minVal:0,
        maxVal:100,
        unit:' (%)',
        colors:rhColors,
        segments:rhSegments,
        value:0,
    },
    {   
        name:'Atm.',
        zone:'Zone5',
        address:66,
        decimal:2,
        factor:-2,
        minVal:90,
        maxVal:110,
        unit:' (kPa)',
        colors:pressureColors,
        segments:pressureSegments,
        value:0,
    },
  
]       