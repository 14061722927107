import axios from 'axios'
import settings from '../configs/setings.json'
//=================================================
async function readDevData() {
    try {
        const res:any = await axios.post(settings.url,{DEVNAME:settings.devName});
        return res.data;
    }catch (error){
        console.log('Error',error);
    }
}
//=================================================
async function readDBData() {
    try {
        const res = await axios.post(settings.dburl,{REQACTION:"GETSQLARR",SQL:"SELECT * FROM faiomai"}, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        return res.data;
    }catch (error){
        console.log('Error',error);
    }
}
//=================================================
async function getZones(companyName:string) {
    try {
        let sql = `SELECT * FROM zones where companyName = '${companyName}'`;
        const res = await axios.post(settings.dburl,{REQACTION:"GETSQLARR",SQL:sql}, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        return res.data;
    }catch (error){
        console.log('Error',error);
    }
}
//=================================================
async function updateZone(zoneName:string,zoneDescription:string) {
    try {
        let sql = `UPDATE \`zones\` SET \`description\` = '${zoneDescription}'  WHERE \`zones\`.\`name\`= '${zoneName}' `;

        
        const res = await axios.post(settings.dburl,{REQACTION:"UPDATESQL",SQL:sql}, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        
        return res.data;
    }catch (error){
        console.log('Error',error);
    }
}
//=================================================

export default {
    readDevData,
    readDBData,
    getZones,
    updateZone,
};
//=================================================