import ReactSpeedometer from "react-d3-speedometer";
import "../../src/index.css";
interface MyGaugeCompProps {
  title: string;
  unit: string;
  val: number;
  minVal: number;
  maxVal: number;
  segments:number;
  colors:string[],
  
}

//==================================================
export function MyGaugeComp({
  val,
  title,
  unit,
  minVal,
  maxVal,
  colors,
  segments,
}: MyGaugeCompProps) {
  
  

  return (
    <div className="Gauge">
      <div>
        {title}
        <b>{unit}</b>
      </div>
      {(colors.length == 2)?
      <ReactSpeedometer
      
        textColor="white"
        minValue={minVal}
        maxValue={maxVal}
        value={val}
        startColor={colors[0]}
        segments={segments}
        endColor={colors[1]}
      />:
      <ReactSpeedometer
        textColor="white"
        minValue={minVal}
        maxValue={maxVal}
        value={val}
        segments={segments}
        segmentColors={colors}
      />
      }
    </div>
  );
}
