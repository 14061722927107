import { useEffect, useState } from "react";

import auth from "./services/auth";
import { MyLoginForm } from "./components/LoginForm";
import { MyMainPage } from "./components/mainPage";
import { DBDataType, DBSensorType, SensorType, UserType } from "./dataTypes/myDataTypes";
import { sensors } from "./configs/Tags";
import serviceData from "./services/dataService";
import { getSensorValue, getTrimmedTime } from "./components/DataBase/dataUtils";

import './App.css';
function App() {
  const [user, setUser] = useState<UserType | undefined>(auth.getUser());
  
  const [allData, setAllData] = useState<DBDataType[]>([]);

  async function getDBData() {
    let ds: any[] = await serviceData.readDBData();
    let dbs:DBDataType[] = [];
    
    ds.forEach(d=>{

        let db:DBDataType = {
            id: d.id,
            date: d.date,
            time: d.time,
            data: d.data,
            MyDate:{
              D:d.date.substring(8,10),
              M:d.date.substring(5,7),
              Y:d.date.substring(0,4),
              H:d.time.substring(0,2),
              m:d.time.substring(3,5),
            },
            hour:d.time.substring(0,2),
            day:d.date.substring(8,10),
            month:d.date.substring(5,7),
            year:d.date.substring(0,4),
            hourMinute: getTrimmedTime(d.time),
            DbSensors:[],
        }

        let dataArr = db.data.substring(1, db.data.length - 1).split(",");
        sensors.forEach(sensor=>{
          let sensorDB:DBSensorType = {
            name: sensor.name,
            zone: sensor.zone,
            decimal:sensor.decimal,
            value: getSensorValue(sensor,dataArr)
          }
          db.DbSensors.push(sensorDB)
        })
        dbs.push(db)
    })
    setAllData([...dbs])
  }
  useEffect(() => {
    getDBData();
  }, []);
  return (
    <div className="App">
      {user && <MyMainPage user={user} allData={allData}/>}
      {!user && (
        <MyLoginForm
          onLog={() => {setUser(auth.getUser());}}
        />
      )}
    </div>
  );
}

export default App;
